jQuery(document).ready(function () {
  
  

  jQuery(".hero-home").imagesLoaded(function () {
    // Animate it for coolness
    
    console.log('Loaded home hero images.');

    // Hero fade in
    var h1 = jQuery(".hero-home img");
    var heroTween = new TimelineMax();

    heroTween
      .fromTo(h1, 0.4, { opacity: 0, left: -20 }, { delay: 0, opacity: 1, left: 0 })
      .play();
    
    
    //setTimeout(function () {
      jQuery(".hero-home").addClass("inplace");
    //}, 100);

  });
});
